import React, {useRef, useEffect} from 'react';
import styled, { keyframes, css } from 'styled-components';
import TransitionLink from 'gatsby-plugin-transition-link';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'gatsby';
import SEO from '../components/seo';

import TransContainer from '../components/TransContainer';
import Layout from '../components/layout';
import ThreeDCard from '../components/ThreeDCard';
import MainText from '../components/shared/MainText';

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const colorChange = keyframes`
  from {
    color: #000000;
  }
  to {
    color: #ffffff;
  }
`;

const Title = styled.h1`
  position: absolute;
  color: #000;
  animation: ${({ whiteTitle }) =>
    whiteTitle
      ? css`
          ${colorChange} 0.5s ease-in-out forwards
        `
      : 'none'};
  font-size: 14.5vw;
  line-height: 1;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 22px 15px 0;

  @media (min-width: 800px) {
    color: #000;
    padding: 22px 20px 0;
    font-size: 100px;
    position: relative;
    z-index: 1;
    animation: none;
  }
`;

const CardWrapper = styled.div`
  position: relative;
  top: 0;
  z-index: -1;
  width: 100vw;
  text-align: center;

  @media (min-width: 800px) {
    position: static;
    height: initial;
    width: initial;
    margin-top: -7vh;
  }

  & > div {
    position: relative;
    display: inline-block;
    bottom: 0;
    right: 0;
  }
`;

const Video = styled.video`
  width: 100vw;
  height: auto;

  @media (min-width: 800px) {
    height: 80vh;
    width: auto;
  }
`;

const Photo = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;

  @media (min-width: 800px) {
    height: calc(100vh - 100px);
    width: 85vw;
  }
`;

const StyledMainText = styled(MainText)`
  margin: 100vh 0 100px;
  margin-top: 60px;

  @media (min-width: 800px) {
    margin: 120px 0;
  }
`;

const ImageDuoWrapper = styled.div`
  @media (min-width: 800px) {
    display: flex;
    padding: 0 90px;
    align-items: center;
    margin-bottom: 120px;
  }
`;

const ImageDuoItem = styled.img`
  padding: 0 15px;
  margin-bottom: 20px;

  @media (min-width: 800px) {
    width: 50%;
    height: auto;
    max-height: 600px;
    object-fit: cover;
    object-fit: contain;
    object-position: left;
    margin-bottom: 0;
    padding: 0 20px;
  }
`;

const ImageDuoItem2 = styled(ImageDuoItem)`
  object-position: right;
`;

const BigImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 100px;

  @media (min-width: 800px) {
    height: ${({ horizontal }) => (horizontal > 1 ? 'calc(100vw - 40px)' : 'auto')};
    width: calc(100vw - 40px);
    object-fit: contain;
    object-position: center;
    margin-bottom: 120px;
  }
`;

const BigLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  margin: 120px 0 140px;
  text-align: center;

  @media (min-width: 800px) {
    margin: 120px 0 120px;
    padding: 0 20px;
  }
`;

const BigLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: kormelink;
  letter-spacing: 0.1px;
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 10px;

  @media (min-width: 800px) {
    font-size: 38px;
    line-height: 47px;
    margin-bottom: 20px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const BigLinkBack = styled(BigLink)`
  background-color: transparent;

  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: transparent;
  }
`;

const MainTextCredits = styled(MainText)`
  margin-top: 0;
  p {
    margin-bottom: 0;
  }
`;

const Project = ({
  pageContext: {
    title,
    whiteTitle,
    posterImage,
    mobileImage,
    description,
    imageDuo1,
    imageDuo2,
    mp4VideoFile,
    mobileVideoMp4,
    bigImage,
    videoLinkUrl,
    videoLinkLabel,
    fileLinkLabel,
    file,
    credits,
    ...rest
  },
  ...props
}) => {

  const videoEl = useRef(null);

  useEffect(() => {
    if (videoEl && videoEl.current && typeof window !== 'undefined' && window.innerWidth < 800 ) {
      videoEl.current.muted = true;
      videoEl.current.play();
    }
  }, []);

  const renderName = name => {
    const splitName = name.split('');
    let newName = [];

    splitName.forEach((item, i) => {
      newName.push(<span key={item + i}>{item}</span>);
    });

    return newName;
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 799px)' });

  return (
    <Layout transitionStatus={props.transitionStatus}>
      <SEO title={'Paweł Sakowicz | ' + title} keywords={[`Paweł Sakowicz`, `choreography`, `dance`]} />
      <Wrapper>
        <Title whiteTitle={whiteTitle}>{renderName(title.toUpperCase())}</Title>
        <TransContainer>
          <CardWrapper>
            <ThreeDCard>
            {typeof window !== 'undefined' && 
              (!isTabletOrMobile && mp4VideoFile) || (isTabletOrMobile && mobileVideoMp4) ? (
                <Video ref={videoEl} autoPlay loop muted={true} defaultMuted playsInline poster={isTabletOrMobile ? mobileImage.url : posterImage.url}>
                  <source src={typeof window !== 'undefined' && window.innerWidth < 800  ? mobileVideoMp4.url : mp4VideoFile.url} type='video/mp4' />
                </Video>
              ) : (
                <Photo imageUrl={typeof window !== 'undefined' && window.innerWidth < 800  ? mobileImage.url : posterImage.url} />
              ) 
            }             
            </ThreeDCard>
          </CardWrapper>

          <StyledMainText dangerouslySetInnerHTML={{ __html: description.html }}></StyledMainText>
          <ImageDuoWrapper>
            {imageDuo1 && <ImageDuoItem src={imageDuo1.url}></ImageDuoItem>}
            {imageDuo2 && <ImageDuoItem2 src={imageDuo2.url}></ImageDuoItem2>}
          </ImageDuoWrapper>
          {bigImage && <BigImage horizontal={bigImage.height / bigImage.width} src={bigImage.url}></BigImage>}

          { credits && (
            <MainTextCredits dangerouslySetInnerHTML={{ __html: credits.html }}></MainTextCredits>
          )}

          <BigLinksWrapper>
            {videoLinkUrl && videoLinkLabel && (
              <BigLink href={videoLinkUrl} target='_blank'>
                <span role='img' aria-label='copy icon'>
                  🔗
                </span>
                &nbsp;<span>{videoLinkLabel}</span>
              </BigLink>
            )}
            {file && fileLinkLabel && (
              <BigLink href={file.url} target='_blank'>
                <span role='img' aria-label='pen icon'>
                  🖋
                </span>
                &nbsp;<span>{fileLinkLabel}</span>
              </BigLink>
            )}
            <BigLinkBack as='div'>
              <Link to='/'>&#x021A9; Back</Link>
            </BigLinkBack>
          </BigLinksWrapper>
        </TransContainer>
      </Wrapper>
    </Layout>
  );
};

export default Project;
